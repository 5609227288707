import React from 'react';
import { Box, Stack } from '@mui/material';
import { differenceInSeconds } from 'date-fns';
import moment from 'moment';
import noDataImage from '@assets/images/customer/no_data.svg';
import { useLocation } from 'react-router-dom';
import { loadFromLocalStorageObjectFromBase64 } from '@databases/localStorage';
import { contantAuthentication } from '@contants/index';
import { isEmpty } from 'lodash';
import axios from 'axios';

export const FORMAT_DATE_ISO = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

export const convertHexToRGB = (hex) => {
  // check if it's a rgba
  if (hex.match('rgba')) {
    let triplet = hex.slice(5).split(',').slice(0, -1).join(',');
    return triplet;
  }

  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');

    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
  }
};

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

export function isMobile() {
  if (window) {
    return window.matchMedia(`(max-width: 767px)`).matches;
  }
  return false;
}

export function isMdScreen() {
  if (window) {
    return window.matchMedia(`(max-width: 1199px)`).matches;
  }
  return false;
}

function currentYPosition(elm) {
  if (!window && !elm) {
    return;
  }
  if (elm) return elm.scrollTop;
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
    return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

function elmYPosition(elm) {
  var y = elm.offsetTop;
  var node = elm;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }
  return y;
}

export function scrollTo(scrollableElement, elmID) {
  var elm = document.getElementById(elmID);

  if (!elmID || !elm) {
    return;
  }

  var startY = currentYPosition(scrollableElement);
  var stopY = elmYPosition(elm);

  var distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    scrollTo(0, stopY);
    return;
  }
  var speed = Math.round(distance / 50);
  if (speed >= 20) speed = 20;
  var step = Math.round(distance / 25);
  var leapY = stopY > startY ? startY + step : startY - step;
  var timer = 0;
  if (stopY > startY) {
    for (var i = startY; i < stopY; i += step) {
      setTimeout(
        (function (leapY) {
          return () => {
            scrollableElement.scrollTo(0, leapY);
          };
        })(leapY),
        timer * speed
      );
      leapY += step;
      if (leapY > stopY) leapY = stopY;
      timer++;
    }
    return;
  }
  for (let i = startY; i > stopY; i -= step) {
    setTimeout(
      (function (leapY) {
        return () => {
          scrollableElement.scrollTo(0, leapY);
        };
      })(leapY),
      timer * speed
    );
    leapY -= step;
    if (leapY < stopY) leapY = stopY;
    timer++;
  }
  return false;
}

export function getTimeDifference(date) {
  let difference = differenceInSeconds(new Date(), date);

  if (difference < 60) return `${Math.floor(difference)} sec`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} min`;
  else if (difference < 86400) return `${Math.floor(difference / 3660)} h`;
  else if (difference < 86400 * 30) return `${Math.floor(difference / 86400)} d`;
  else if (difference < 86400 * 30 * 12) return `${Math.floor(difference / 86400 / 30)} mon`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} y`;
}

export function generateRandomId() {
  let tempId = Math.random().toString();
  let uid = tempId.substr(2, tempId.length - 1);
  return uid;
}

export function getQueryParam(prop) {
  var params = {};
  var search = decodeURIComponent(
    window.location.href.slice(window.location.href.indexOf('?') + 1)
  );
  var definitions = search.split('&');
  definitions.forEach(function (val, key) {
    var parts = val.split('=', 2);
    params[parts[0]] = parts[1];
  });
  return prop && prop in params ? params[prop] : params;
}

export function classList(classes) {
  return Object.entries(classes)
    .filter((entry) => entry[1])
    .map((entry) => entry[0])
    .join(' ');
}

export const flat = (array) => {
  var result = [];
  array.forEach(function (a) {
    result.push(a);
    if (Array.isArray(a.children)) {
      result = result.concat(flat(a.children));
    }
  });
  return result;
};

export function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

let delayTimer;
export const doSearch = (callback) => {
  clearTimeout(delayTimer);
  delayTimer = setTimeout(function () {
    callback();
  }, 1000); // Will do the ajax stuff after 1000 ms, or 1 s
};

export const formatDateToString = (date, patten = 'DD-MM-YYYY HH:mm') =>
  date ? moment(date).format(patten).toString() : '';

export const convertLocaltimeToUTC = (date, hour) => {
  let result = null;
  try {
    if (date) {
      const localDateTimeMoment = moment(
        `${moment(new Date(date)).format('YYYY-MM-DD')} ${hour}`,
        'YYYY-MM-DD HH:mm:ss.SSS'
      );

      const utcDateTimeMoment = localDateTimeMoment.utc();

      result = utcDateTimeMoment.toISOString();
    }
  } catch (e) {
    console.log('Exception at convertLocaltimeToUTC');
    console.log(e);
  }

  return result;
};

export function isNullOrEmpty(value) {
  return value === undefined || value === null || value === '';
}

export function formatCurrency(money, digits) {
  if (isNullOrEmpty(money)) {
    return '0';
  }
  let format = '$1,';
  return `${digits ? money : Math.trunc(+money)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, format);
}

export const formatVNDCurrency = (value) => {
  if (!value) return '0';
  const formattedValue = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND'
  }).format(value);
  return formattedValue.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const formatValue = (formattedValue) => {
  if (!formattedValue) return '';
  // Remove all non-digit characters except for periods
  let value = formattedValue?.toString().replace(/[^\d.]/g, '');
  let parts = value.split('.');
  let integerPart = parts[0];
  let decimalPart = parts.length > 1 ? '.' + parts[1] : '';
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Combine the integer and decimal parts
  return integerPart + decimalPart;
};
export const getTimeDifferenceV2 = (targetDateStr) => {
  let targetDate = moment(targetDateStr);
  let now = moment();

  if (targetDate.isBefore(now) || !targetDateStr) {
    return {
      milliseconds: 0,
      seconds: 0,
      minutes: 0,
      hours: 0,
      days: 0
    };
  }

  let diffInMilliseconds = targetDate.diff(now, 'milliseconds');
  let diffInSeconds = targetDate.diff(now, 'seconds');
  let diffInMinutes = targetDate.diff(now, 'minutes');
  let diffInHours = targetDate.diff(now, 'hours');
  let diffInDays = targetDate.diff(now, 'days');

  return {
    milliseconds: diffInMilliseconds,
    seconds: diffInSeconds,
    minutes: diffInMinutes,
    hours: diffInHours,
    days: diffInDays
  };
};

export const Nodata = ({ text }) => {
  return (
    <Box>
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
        <img src={noDataImage}></img>
        <p>{text}</p>
      </Stack>
    </Box>
  );
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const downloadBlobFile = async (url, fileName, data, typeReq = 'GET') => {
  let user = loadFromLocalStorageObjectFromBase64(contantAuthentication.DATA_AUTH);
  if (!isEmpty(user)) {
    user = JSON.parse(user);
  } else {
    return;
  }
  const response =
    typeReq === 'GET'
      ? await axios.get(url, {
          params: data,
          headers: { Authorization: `Bearer ${user?.access_token}` },
          responseType: 'blob'
        })
      : await axios.post(url, null, {
          params: data,
          headers: { Authorization: `Bearer ${user?.access_token}` },
          responseType: 'blob'
        });
  const type = response.headers['content-type'];
  const blob = new Blob([response.data], { type: type });
  const link1 = document.createElement('a');
  link1.href = window.URL.createObjectURL(blob);
  link1.setAttribute('download', fileName);
  document.body.appendChild(link1);
  link1.click();
};
