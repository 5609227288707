import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { Breadcrumb } from '@components/index';
import { withPaginate } from '@contexts/Paginate/PaginateContext';
import ContainerBody from '@components/ContainerBody';
import { ProductPreview } from '@page/AdminPage/Auction/components/ProductPreview';
import { ProductDetail } from '@page/AdminPage/Auction/components/ProductDetail';
import { SummaryRight } from '@page/AdminPage/Auction/components/SummaryRight';
import { ProductOption } from '@page/AdminPage/Auction/components/ProductOption';
import { getDetailProduct } from '@page/AdminPage/Auction/helpers/request';
import { ResponseStatusCode, SUCCESS } from '@utils/baseAPI/HttpStatusCode';
import { Avatar, Button, Col, Row, Skeleton, Spin, Typography } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ProductDescription } from '@page/AdminPage/Auction/components/ProductDescription';
import ProcductRelative from './components/ProductRelative';
import ProcductRecentlyViewed from './components/ProductRecently';
import { ReactComponent as Share } from '@assets/images/customer/share.svg';
import './index.css';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import NotFoundPro from '@page/Page404Product';
import { URI_CRAWL } from '@contants/options';
import AuctionPageBild from '@page/PageAuctionBild';
import { useQuery } from 'react-query';
import rootApi from '@utils/baseAPI/WrapperApi';
import { Select, Space } from 'antd';
import CustomerDefaultImage from '@assets/images/customer/default_avatar.png';
import { isEmpty } from 'lodash';
const { Title, Text } = Typography;
const { Option } = Select;
const Auction = ({ uriCustomer, customerId }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { params: paramsValue } = useParams();
  const [productDetail, setProductDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [favorite, setFavorite] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isOpenModalAuction, setIsOpenModalAuction] = useState(false);
  const [resultAuction, setResultAuction] = useState();
  const [roleSale, setRoleSale] = useState(null);
  const [customerSelect, setCustomerSelect] = useState('');
  const [customerSelectId, setCustomerSelectId] = useState(queryParams.get('customer_id'));
  const navigate = useNavigate();

  const [valueSearch, setValueSearch] = useState('');
  const handleGetDataAuction = (data) => {
    setResultAuction(data);
  };

  const { data } = useQuery(['get-user-info'], () => rootApi.get('/users/me'));
  const userInfo = data?.data?.data;

  useEffect(() => {
    if (userInfo) {
      const roleUser = userInfo.roles.some((role) => role === 'SALE');
      setRoleSale(roleUser);
    }
  }, [userInfo]);
  const { data: customerLists, refetch } = useQuery(
    ['admin-get-all-users', valueSearch],
    () =>
      roleSale
        ? rootApi.get(`/admin/users`, {
            params: {
              search_username: valueSearch ?? undefined
            }
          })
        : Promise.resolve(), // Return a resolved promise when role is not 'sale'
    {
      enabled: !!roleSale // Only enable the query when roleSale is true
    }
  );
  const customerList = customerLists?.data?.data || [];
  let customerRedirect;
  if (location?.state) {
    customerRedirect = location?.state?.rowData;
  }

  useEffect(() => {
    if (queryParams.get('customer_id') && !isEmpty(customerList)) {
      const customer = customerList.find(
        (cust) => cust.id === Number(queryParams.get('customer_id'))
      );
      setCustomerSelect(customer ? customer.username : null);
    } else {
      setCustomerSelect(null);
    }
  }, [customerList, location]);

  const handleCustomerChange = (value, option) => {
    const selectedCustomerId = +option.key;
    const selectedCustomer = customerList.find((cust) => cust.id === selectedCustomerId);

    if (selectedCustomer) {
      setCustomerSelect(selectedCustomer.username);
      setCustomerSelectId(selectedCustomer?.id);
      const source = paramsValue ? `${URI_CRAWL}/${paramsValue}` : uriCustomer?.trim();
      if (source) {
        fetchProductDetail(source, null, selectedCustomer?.id);
      }
      queryParams.set('customer_id', selectedCustomer?.id);
      navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
    }
  };

  const handleSearch = (value) => {
    setValueSearch(value);
    refetch();
  };

  useEffect(() => {
    const fetchDetails = () => {
      if (paramsValue) {
        const uri = `${URI_CRAWL}/${paramsValue}`;
        fetchProductDetail(uri, userInfo?.id);
      } else if (uriCustomer) {
        fetchProductDetail(uriCustomer?.trim(), customerId);
      }
    };
    fetchDetails();
  }, [paramsValue, isOpenModalAuction, uriCustomer]);

  useEffect(() => {
    if (location.search && location.search.includes('dataSearch=')) {
      const params = location.search.split('dataSearch=')[1];
      fetchProductDetail(params);
    }
  }, [location]);

  const fetchProductDetail = (source, customerId, customerChangeId) => {
    if (source === '' || !source) return;
    setIsLoading(true);
    const params = {
      source: source
    };
    params.customer_id = customerId ?? customerChangeId ?? Number(customerSelectId);
    getDetailProduct(params)
      .then((res) => {
        setIsLoading(false);
        if (res.status === SUCCESS) {
          res.data.images = res.data.images.map((item) => {
            return {
              original: item,
              thumbnail: item
            };
          });
          setProductDetail(res.data);
          setIsNotFound(false);
        } else if (res.status === ResponseStatusCode.NOT_FOUND) {
          setProductDetail(res.data);
          setIsNotFound(true);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <ContainerBody>
      <div
        className="product_auction_containers"
        style={{ backgroundColor: isNotFound ? '#f4f5f7' : '#fafafa' }}>
        {isLoading ? (
          <div style={{ textAlign: 'center', marginTop: 30 }}>
            <Skeleton active />
          </div>
        ) : isNotFound ? (
          <>
            <NotFoundPro data={roleSale} />
          </>
        ) : // : resultAuction?.price?.is_bid_win !== undefined ? (
        //   <AuctionPageBild data={resultAuction} roleSale={roleSale} />
        // )
        productDetail ? (
          <>
            {!roleSale ? (
              <div className="breadcrumb">
                <Breadcrumb
                  homePath={'/customer/dashboard'}
                  routeSegments={[{ name: 'Sản phẩm', path: '/account/auction' }]}
                />
              </div>
            ) : (
              ''
            )}
            {productDetail ? (
              <>
                {roleSale && location?.pathname?.split('/account/auction/')[1] ? (
                  <>
                    <div className="auction_customer_top_row">
                      <h2>Đấu giá hộ sản phẩm</h2>
                      <div className="auction_customer_top_row_left_share">
                        <span className="customer-label">Khách hàng:</span>
                        <Select
                          showSearch
                          placeholder="Chọn khách hàng"
                          optionFilterProp="children"
                          onChange={handleCustomerChange}
                          value={customerSelect}
                          style={{ width: 250 }} // Adjust width to match UI
                          dropdownRender={(menu) => (
                            <div>
                              <div style={{ padding: '8px', borderBottom: '1px solid #f0f0f0' }}>
                                <input
                                  placeholder="Tìm kiếm"
                                  onChange={(e) => handleSearch(e.target.value)}
                                  style={{
                                    width: '100%',
                                    borderRadius: '4px',
                                    padding: '8px 12px'
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  padding: '8px', // Adjust padding for menu items
                                  fontSize: '14px',
                                  fontStyle: 'normal'
                                }}>
                                {menu}
                              </div>
                            </div>
                          )}
                          className="customer-select">
                          {customerList.map((customer) => (
                            <Option key={customer.id} value={customer.username}>
                              <div className="customer-item">
                                <Avatar
                                  src={customer.avatar || CustomerDefaultImage}
                                  size="small"
                                  className="customer-avatar"
                                  style={{ marginRight: 8 }}
                                />
                                <div className="customer-info">
                                  <div className="customer-name">{customer.username}</div>
                                  <div className="customer-phone">{customer.phoneNumber}</div>
                                </div>
                              </div>
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
            {productDetail ? (
              <>
                <div
                  className="product_auction_container "
                  style={{ padding: uriCustomer ? '16px' : '24px' }}>
                  <Row
                    gutter={[16, 16]}
                    justify="start"
                    style={{
                      marginTop: '16px',
                      marginLeft: '0px',
                      paddingRight: '0',
                      borderBottom: ' 1px solid var(--Stoke-Stoke, #DBDFE5)'
                    }}>
                    <Col md={8} style={{ paddingRight: 0 }}>
                      <div className="section">
                        <ProductPreview data={productDetail?.images} />
                        <div className="left_bot_section">
                          <div className="left_bot_section_row">
                            <div className="left_bot_section_share">
                              <span style={{ display: 'block' }}>Chia sẻ</span>
                              <Button
                                type="text"
                                icon={<Share className="left_bot_section_share_icon" />}
                                key="share"
                                style={{ borderRadius: '36px' }}
                              />
                            </div>
                            <div className="left_bot_section_favorite">
                              <Button
                                type="text"
                                icon={
                                  favorite ? (
                                    <HeartFilled style={{ color: '#626D7C', fontSize: '19px' }} />
                                  ) : (
                                    <HeartOutlined style={{ color: '#626D7C', fontSize: '19px' }} />
                                  )
                                }
                                key="favorite"
                                style={{ borderRadius: '36px' }}
                                onClick={() => setFavorite(!favorite)}
                              />
                              <span style={{ display: 'block' }}> Yêu thích</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={12} style={{ padding: '0 24px 24px' }}>
                      <ProductDetail
                        data={productDetail}
                        refetch={fetchProductDetail}
                        isOpenModalAuction={isOpenModalAuction}
                        setIsOpenModalAuction={setIsOpenModalAuction}
                        handleGetDataAuction={handleGetDataAuction}
                        roleSale={roleSale}
                        customerId={customerId}
                        customerSelectId={customerSelectId}
                      />
                    </Col>
                    <Col md={4}>
                      <SummaryRight
                        data={productDetail}
                        yahoo_id={productDetail?.detail?.auction_id}
                      />
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              <div style={{ position: 'relative', width: '100%' }}>
                {isLoading && (
                  <Spin
                    style={{ position: 'absolute', top: 10, left: '50%' }}
                    spinning={isLoading}
                  />
                )}
                {!isLoading && (
                  <div style={{ margin: 'auto', marginTop: 20, textAlign: 'center' }}>
                    Vui lòng tìm kiếm sản phẩm để đấu giá
                  </div>
                )}
              </div>
            )}
            {productDetail && (
              <>
                <div>
                  <ProductOption data={productDetail} />
                </div>
              </>
            )}
            {!roleSale && (
              <>
                <ProcductRelative />
              </>
            )}
            {productDetail && (
              <>
                <div style={{ backgroundColor: '#0000' }}>
                  <ProductDescription data={productDetail} />
                </div>
              </>
            )}
            {!roleSale && <ProcductRecentlyViewed />}
          </>
        ) : (
          <>
            <NotFoundPro data={roleSale} />
          </>
        )}
      </div>
    </ContainerBody>
  );
};

export default compose(withPaginate)(Auction);
