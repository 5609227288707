import React from 'react';
import Loadable from '@components/Loadable';
import { lazy } from 'react';
import UserDetail from './ManageUserOperator/components/Detail';

const Customer = Loadable(lazy(() => import('../AdminPage/ManageCustomer')));
const DetailCustomerPage = Loadable(lazy(() => import('./ManageCustomer/DetailCustomer/index')));
const AuctionCustomerPage = Loadable(lazy(() => import('./ManageCustomer/Auction/index')));
const CustomerFeesPage = Loadable(lazy(() => import('./ManageCustomer/Fees/index')));
const UserOperator = Loadable(lazy(() => import('../AdminPage/ManageUserOperator')));
const Role = Loadable(lazy(() => import('../AdminPage/ManageRole')));
const Cookies = Loadable(lazy(() => import('../AdminPage/ManageCookies')));
const AdminUserProduct = Loadable(lazy(() => import('../AdminPage/AdminUserProduct')));
const SetupFee = Loadable(lazy(() => import('../AdminPage/SetupFee')));
const AuctionSuccess = Loadable(lazy(() => import('../AdminPage/ManageAuctions')));

const adminRouters = [
  { path: '/account/profile', element: <AdminUserProduct /> },
  { path: '/admin/customer', element: <Customer /> },
  { path: '/admin/customer/:id', element: <DetailCustomerPage /> },
  { path: '/admin/customer/auction/:id', element: <AuctionCustomerPage /> },
  { path: '/admin/fees/customer', element: <CustomerFeesPage /> },

  {
    path: '/admin/user',
    element: <UserOperator />
  },
  {
    path: '/admin/user/:id',
    element: <UserDetail />
  },
  {
    path: '/admin/role',
    element: <Role />
  },
  {
    path: '/admin/cookies',
    element: <Cookies />
  },
  {
    path: '/admin/setup-fee',
    element: <SetupFee />
  },
  {
    path: '/admin/auctions',
    element: <AuctionSuccess />
  }
];

export default adminRouters;
