import rootApi from '@utils/baseAPI/WrapperApi';

export const apiGetListAuctionSuccess = (data) => {
  return rootApi.get('/admin/user-product', { params: data });
};

export const apiGetAuctionSuccessDetail = (id) => {
  return rootApi.get(`admin/user-product/get-detail/${id}`);
};

export const apiGetCurrencies = (params) => {
  return rootApi.get(`/admin/currencies/detail`, { params });
};

export const apiGetGlobalConfigMass = (params) => {
  return rootApi.get(`/global-configs`, { params });
};
export const apiUpdateFee = (id, data) =>
  rootApi.post(`/admin/user-product/update-fee/${id}`, data);
export const apiUpdateCustomer = (userProductId, data) =>
  rootApi.post(`admin/user-product/update/${userProductId}`, data);

export const scheduleBidding = () => {
  return rootApi.get(`schedule/bidding`);
};
