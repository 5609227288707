import React from 'react';
import { Button, Modal } from 'antd';
import { Svgs } from '@components/IconSvg';
import { useNavigate } from 'react-router-dom';

const PurchaseSuccessModal = ({ isVisible, onClose, roleSale, customerId }) => {
  const navigate = useNavigate();
  const handleRedirect = () => {
    if (roleSale) {
      navigate('/admin/auctions');
    } else {
      navigate('/account/user-auction');
    }
  };
  const handleRedirectContinue = () => {
    if (roleSale) {
      navigate(`/admin/customer/auction/${customerId}`);
    } else {
      navigate('/customer/dashboard');
    }
  };
  return (
    <Modal
      visible={isVisible}
      footer={null}
      onCancel={onClose}
      centered
      width={450}
      bodyStyle={{
        padding: '24px',
        textAlign: 'center',
        borderRadius: '10px',
        backgroundColor: '#FFFFFF'
      }}
      closable={false} // To remove the default close button
    >
      <div style={{ padding: '20px 0' }}>
        <div
          style={{
            display: 'inline-block',
            padding: '16px',
            borderRadius: '50%',
            backgroundColor: 'rgba(0, 144, 74, 0.10)',
            marginBottom: '16px'
          }}>
          <Svgs.BagSvg style={{ fontSize: '64px', color: '#00904A' }} />
        </div>
        <h2
          style={{
            color: 'var(--Text-colorcolor-Text-primaty, #192434)',
            fontWeight: '600',
            fontSize: '24px',
            marginBottom: '10px',
            fontStyle: 'normal'
          }}>
          Mua thẳng thành công!
        </h2>
        <p
          style={{
            fontSize: '14px',
            color: ' var(--Text-colorcolor-Text-secondary, #626D7C)',
            marginBottom: '30px',
            fontStyle: 'normal'
          }}>
          Xin chúc mừng bạn đã mua hàng thành công.
        </p>

        <div style={{ marginTop: '24px' }}>
          <Button
            size="large"
            style={{
              width: '180px',
              height: '45px',
              borderRadius: '8px',
              color: ' var(--Text-colorcolor-Text-primaty, #192434);',
              backgroundColor: 'var(--Backgroud-Backgroud-button, #E8EBEF);',
              marginRight: '12px'
            }}
            onClick={() => handleRedirect()}>
            Theo dõi đơn hàng
          </Button>
          <Button
            type="primary"
            size="large"
            style={{
              width: '180px',
              height: '45px',
              borderRadius: '8px',
              color: 'var(--Backgroud-Backgroud-1, #FFF);',
              backgroundColor: 'var(--Primary-Basic, #00904A)'
            }}
            onClick={() => handleRedirectContinue()}>
            Tiếp tục mua hàng
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PurchaseSuccessModal;
