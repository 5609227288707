import React, { useState } from 'react';
import rootApi from '@utils/baseAPI/WrapperApi';
import { Button, Modal, Table, Typography } from 'antd';
import { useQuery } from 'react-query';
import './DialogsHistory.css'; // Import the CSS file
import * as moment from 'moment';

const columns = ({ refetch }) => {
  return [
    {
      title: 'Người đấu giá',
      dataIndex: 'auction_account_name',
      render: (_, d) => (
        <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#00904A' }}>
          {d?.username}
        </Typography>
      ),
      width: 220
    },
    {
      title: 'Thời gian đặt',
      dataIndex: 'time',
      render: (_, d) => (
        <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#00904A' }}>
          {moment(d?.time).format('DD-MM-YYYY HH:mm')}
        </Typography>
      ),
      width: 220
    },
    {
      title: 'Giá đặt',
      dataIndex: 'price',
      render: (_, d) => (
        <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#00904A' }}>
          {d?.price}
        </Typography>
      ),
      width: 220
    }
  ];
};

const DialogsHistoryAuction = ({ onCancel, visible, data: product }) => {
  // Quản lý trạng thái phân trang
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  // Fetch dữ liệu với phân trang
  const {
    data: stepPrice,
    isLoading,
    refetch
  } = useQuery(
    ['get-step-price', currentPage, pageSize], // Đảm bảo react-query refetch khi currentPage hoặc pageSize thay đổi
    () =>
      rootApi.get(`/crawls/step-price`, {
        params: {
          product_id: product?.detail?.auction_id,
          page: currentPage, // Sử dụng currentPage
          limit: pageSize // Sử dụng pageSize
        }
      })
  );

  const stepPriceData = stepPrice?.data?.data || [];
  const totalItems = stepPrice?.data?.total || 0; // Tổng số phần tử để hiển thị tổng số trang

  // Hàm thay đổi trang
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current); // Cập nhật trang hiện tại
    setPageSize(pagination.pageSize); // Cập nhật pageSize nếu thay đổi
  };

  return (
    <div>
      <Modal
        title="Lịch sử đấu giá sản phẩm"
        visible={visible}
        onCancel={onCancel}
        footer={[
          <Button
            key="back"
            style={{
              height: '36px',
              backgroundColor: '#E8EBEF',
              color: '#192434',
              fontSize: '14px',
              fontWeight: '500',
              borderRadius: '4px'
            }}
            onClick={onCancel}>
            Đóng
          </Button>
        ]}
        width={720}
        style={{
          paddingLeft: '16px',
          paddingRight: '16px',
          cursor: 'pointer'
        }}>
        <Table
          bordered
          size="small"
          loading={isLoading}
          dataSource={stepPriceData || []}
          columns={columns({ refetch })}
          pagination={{
            current: currentPage, // Trang hiện tại
            pageSize: pageSize, // Số phần tử mỗi trang
            total: totalItems, // Tổng số phần tử
            showSizeChanger: true, // Cho phép người dùng thay đổi pageSize
            showTotal: (total, range) => `${range[0]}-${range[1]} của ${total} mục`
          }}
          onChange={handleTableChange} // Hàm xử lý khi thay đổi trang hoặc pageSize
          rowKey={(record) => record.id}
          className="custom-table" // Add a custom class for additional styling
        />
      </Modal>
    </div>
  );
};

export default DialogsHistoryAuction;
