import React, { useState } from 'react';
import { Modal, Form, Input, Button, Select, Divider } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { useFormik } from 'formik';
import * as yup from 'yup';
import rootApi from '@utils/baseAPI/WrapperApi';
import get from 'lodash/get';
import { showSuccess, showError } from '@components/notification';
import { useNavigate } from 'react-router-dom';
import { apiUpdateRoleUserOperator } from '@services/ManageUserOperator/UserOperatorServices';

const { Option } = Select;

const DialogsChangeRole = (props) => {
  const { userInfo, open, setOpen, refetch } = props;
  const { data } = useQuery(['get-role-list'], () =>
    rootApi.get('/admin/roles', {
      params: {
        page: 0,
        size: 99999
      }
    })
  );
  const roles = data?.data.data || [];
  const navigate = useNavigate();
  const role_options = roles.map((item) => {
    return {
      label: `${item?.role_name}`,
      value: item?.role_name
    };
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    refetch && refetch();
    setOpen(false);
  };

  const roleSelected = role_options?.filter((item) => userInfo?.roles?.includes(item.label));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      roleNames: userInfo?.roles
    },
    validationSchema: yup.object({
      roleNames: yup.array().required().label('Quyền người dùng')
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const res = await apiUpdateRoleUserOperator(userInfo?.id, {
          role_names: values.roleNames
        });
        handleClose();
        refetch && refetch();
        !refetch && navigate(`/admin/user`);
        showSuccess({ message: 'Cập nhật thông tin người dùng thành công' });
        formik.resetForm();
      } catch (err) {
        console.log('err:::', err);
        throw err;
      } finally {
        setIsLoading(false);
      }
    }
  });

  return (
    <div>
      <Modal
        title="Cập nhật người dùng"
        visible={open}
        onCancel={handleClose}
        footer={[
          <Button key="back" style={{ height: '36px' }} onClick={handleClose}>
            Hủy
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            style={{ backgroundColor: '#00904A', color: '#FFFF', height: '36px' }}
            onClick={formik.handleSubmit}>
            Cập nhật
          </Button>
        ]}>
        <Form layout="vertical">
          <Form.Item label="Tên đăng nhập" style={{ marginBottom: 10 }}>
            <Input value={userInfo?.username} style={{ height: '36px' }} disabled />
          </Form.Item>
          <Form.Item label="Số điện thoại" style={{ marginBottom: 10 }}>
            <Input value={userInfo?.phone_number} style={{ height: '36px' }} disabled />
          </Form.Item>
          <Form.Item label="Email" style={{ marginBottom: 10 }}>
            <Input value={userInfo?.email} style={{ height: '36px' }} disabled />
          </Form.Item>
          <Form.Item
            label="Quyền người dùng"
            validateStatus={formik.errors.roleNames ? 'error' : ''}
            help={formik.errors.roleNames}
            style={{ marginBottom: 10 }}>
            <Select
              mode="multiple"
              name="roleNames"
              // style={{ height: '36px' }}
              value={formik.values.roleNames}
              onChange={(value) => formik.setFieldValue('roleNames', value)}>
              {role_options.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default DialogsChangeRole;
